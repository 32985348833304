<template>
  <nuxt-link
    v-if="show"
    :to="$manualUrl(`checkout/pointshop/${to}`)"
    class="PointShopReminder bg-darkest text-lightest w-full min-h-32
flex justify-center items-center mobOnly:flex-col mobOnly:py-8
type-headline-sm"
  >
    <div class="text-right">
      <div
        class="fixnumbers  mr-16 !normal-case"
        v-html="countdown"
      />
    </div>
    <div class="mr-16">{{ $t('pointshop.reminder') }}</div>
    <div class="underline">{{ $t('pointshop.reminder.click') }}</div>
  </nuxt-link>
</template>

<script setup lang="ts">
const props = defineProps<{
  to: string,
}>();

const toTime = computed(()=> {
  const url = props.to.split('?delayedUntil=');
  return decodeURIComponent(url[1]) || '';
});

const countdown = ref('&nbsp;');
const show = ref(true);

const counter = () => {
  const countDownDate = new Date(toTime.value).getTime();
  const now = new Date().getTime();
  const distance = countDownDate - now;
  let output = '';

  if (distance >= 1) {
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);
    if (days > 0) {
      output += `${days.toString()} d `;
    }
    if (hours > 0 || days > 0) {
      output += `${hours.toString()} t `;
    }
    if (minutes > 0 || hours > 0 || days > 0) {
      output += `<span>${minutes.toString()}</span> m `;
    }
    // stops the annoying jumping by padding seconds and have a fixed width span
    if (minutes > 0 || hours > 0 || days > 0) {
      output += `<span>${seconds.toString().padStart(2, '0')}</span> s`;
    } else {
      output += `<span>${seconds.toString()}</span> s`;
    }

    countdown.value = output;
    // countdown.value = ` ${minutes.toString().padStart(2, '0')} : ${seconds.toString().padStart(2, '0')}`;
  }
  if (distance <= 0) {
    clearInterval(timer.value);
    show.value = false;
  }
};

const timer = ref<any>(null);
onMounted(()=> {
  timer.value = setInterval(counter, 1000);
});

</script>

<style scoped lang="postcss">
.fixnumbers {

  span {
    @apply inline-block w-16 desk:w-20;
  }

}
</style>
