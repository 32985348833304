<template>
  <div
    class="TempMsg flex justify-center fixed w-full max-w-[550px] px-32 bottom-0 desk:right-0 mb-[48px]"
    @click="uiStore.tempMsg = ''"
  >
    <div
      class="successmsg px-24 w-full py-12 uppercase type-sm-medium text-center relative flex justify-center items-center align-items"
      :class="{
        'color-brightGreen': uiStore.tempMsgType === 'success',
        'color-brightRed text-white text-12 py-8': uiStore.tempMsgType === 'error',
      }"
    >
      <img
        v-if="uiStore.tempMsgType === 'error'"
        src="/icons/exclamation-circle-inv.svg"
        class="w-16 h-16 mr-8"
      >
      <div
        :class="{
          'relative top-1': uiStore.tempMsgType === 'success',
        }"
      >
        {{ uiStore.tempMsg }}
      </div>
      <div class="absolute right-8 cursor-pointer">
        <img
          :src="closeIcon"
          class="w-16 h-16"
        >
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUiStore } from '~/store/ui';

const uiStore = useUiStore();

const closeIcon = computed((): string => {
  return uiStore.tempMsgType === 'error'
    ? '/icons/close-inv.svg'
    : '/icons/close.svg';
});

</script>

<style scoped lang="postcss">
.successmsg {
  box-shadow: 5px 5px 10px 1px rgba(0, 0, 0, 0.23);
}
</style>
