<template>
  <footer v-if="footer" class="PageFooter bg-lighter">
    <FooterMenu />
    <div
      class="
            layout-container
            desk:border-t desk:border-light
            desk:min-h-0 pt-16 pb-32
            type-xs-medium text-lightest
            flex flex-col justify-between
            "
    >
      <div class="mobOnly:px-0 desk:flex desk:flex-row justify-between items-center">
        <div class="text-center pt-16 desk:order-1 type-xs-medium desk:pt-0 text-darkest">
          {{ footer.copyright.replace('##YEAR##', new Date().getFullYear()) }}
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { useGlobalContentStore } from '~/store/globalContent';
import FooterMenu from '~/components/body/FooterMenu.vue';

const globalContent = useGlobalContentStore();

const footer = globalContent.getFooter;

</script>

<style scoped lang="postcss">

</style>
