<template>
  <GenericSideMenu
    position="right"
    class="LogInAsCustomerModal"
    content-class="!p-0"
    :title="`${$t('impersonateModal.customerSuccess.title')}`"
    @close="close"
  >
    <div class="px-16 py-24 desk:px-24">
      <div>
        <TextInput
          v-model="companyCode"
          class="w-full"
          type="text"
          :label="$t('impersonateModal.customerSuccess.placeholder')"
        />
      </div>
      <div v-if="listLoading" class="flex justify-center my-16">
        <LoadingIcon />
      </div>
      <div v-else-if="customer" class="pt-24 flex justify-between">
        <div
          class="flex mb-24 cursor-pointer"
        >
          <div class="rounded-full bg-brightGreen w-40 h-40 mr-12 flex justify-center items-center">
            <img src="/icons/smiley.svg" class="h-24 w-24">
          </div>
          <div>
            <div class="type-sm-medium">
              {{ customer.name }}
            </div>
            <div class="type-xs-medium text-dark">
              {{ customer.code }}
            </div>
          </div>
        </div>
        <button
          class="btn"
          :class="{
            'loading': loading
          }"
          @click="impersonate(customer.id)"
        >
          {{ $t('impersonateModal.customerSuccess.cta') }}
        </button>
      </div>
      <div v-else-if="error" class="type-sm-medium mt-16">
        {{ $t('impersonateModal.customerSuccess.error') }}
      </div>
      <div
        v-if="selectedCustomer"
        class="pt-16"
        :class="{
          'border-light border-t': customer,
        }"
      >
        <div v-if="!customer" class="mt-12">
          {{ $t('impersonateModal.customerSuccess.loggedInAs') }}:
        </div>
        <div v-if="!customer" class="pt-12 flex justify-between">
          <div
            class="flex mb-24 cursor-pointer"
          >
            <div class="rounded-full bg-brightGreen w-40 h-40 mr-12 flex justify-center items-center">
              <img src="/icons/smiley.svg" class="h-24 w-24">
            </div>
            <div>
              <div class="type-sm-medium">
                {{ selectedCustomer.name }}
              </div>
              <div class="type-xs-medium text-dark">
                {{ selectedCustomer.code }}
              </div>
            </div>
          </div>
          <button
            class="btn"
            :class="{
              'loading': loading
            }"
            @click="stopImpersonate"
          >
            {{ $t('impersonateModal.customerSuccess.stopImpersonating') }}
          </button>
        </div>
      </div>
    </div>
  </GenericSideMenu>
</template>

<script setup lang="ts">
import GenericSideMenu from '~/components/menu/GenericSideMenu.vue';

import { useUiStore } from '~/store/ui';

import { useUserStore } from '~/store/user';
import TextInput from '~/components/form-elements/TextInput.vue';
import { Company } from '~/constants/types/norce';
import useApiFetch from '~/composeables/useApiFetch';
import debounce from '~/util/debounce';
import LoadingIcon from '~/components/generic/LoadingIcon.vue';
import { storeToRefs } from 'pinia';

const userStore = useUserStore();
const uiStore = useUiStore();

const { userProfile } = storeToRefs(userStore);

const { apiGet, handleApiError } = useApiFetch();

const companyCode = ref('');
const loading = ref(false);
const listLoading = ref(false);
const error = ref(false);
const customer = ref<Company | null>(null);
const selectedCustomer = ref<Company | null>(null);

const getCustomer = async() => {
  if (companyCode.value) {
    listLoading.value = true;
    const res = await apiGet<Company>(`/customer-success/company/${companyCode.value}`);
    if (res) {
      customer.value = res;
    } else {
      error.value = true;
      customer.value = null;
    }
    listLoading.value = false;
  } else {
    customer.value = null;
  }
};

const impersonate = async(id: number) => {
  loading.value = true;
  const res = await userStore.impersonateCompany(id);
  if (res) {
    reload();
  } else {
    handleApiError();
    loading.value = false;
  }
};

const stopImpersonate = async() => {
  loading.value = true;
  await userStore.stopImpersonatingCompany();
  reload();
};

const reload = () => {
  window.location.reload();
};

onMounted(() => {
  if (userProfile.value.impersonatingCompany) {
    selectedCustomer.value = userProfile.value.company;
  }
});

watch(
  companyCode,
  debounce(async() => {
    await getCustomer();
  }, 500)
);

const close = () => {
  uiStore.closeAllModal();
};

</script>

<style scoped lang="postcss">
</style>
