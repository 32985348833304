<template>
  <div class="FooterMenu desk:pb-32 type-xs desk:type-sm">
    <div>
      <div class="">
        <div class="logo-banner mb-24 desk:mb-32" />
      </div>
    </div>
    <div class="mb-24 desk:mb-32 px-12 desk:px-0 desk:layout-container">
      <img
        class="w-full h-auto"
        src="/logos/bywe_logo-long.svg"
      >
    </div>
    <div class="desk:flex desk:layout-container desk:justify-between border-t border-light desk:pt-32">
      <div v-if="!isMobile" class="desk:basis-1/2 grow-0 desk:mr-24">
        <div class="type-headline-sm">
          {{ footer.footerTitle }}
        </div>
        <div class="wysiwyg type-xs-medium w-2/3 mt-16" v-html="renderRichText(footer.footerText)" />
      </div>
      <div
        class="desk:basis-1/2 desk:flex justify-between"
      >
        <div
          class="desk:grid basis-full shrink"
          :class="`grid-cols-${navigation.length}`"
        >
          <div
            v-for="link in navigation"
            :key="link._uid"
            class="mobOnly:border-b mobOnly:border-light mobOnly:py-16 mobOnly:px-12"
          >
            <div
              class="flex justify-between cursor-pointer"
              @click="clickMenu($sbLinkFix(link.link), (link.children.length > 0), link._uid)"
            >
              <div class="type-headline-sm">
                {{ link.title }}
              </div>
              <div v-if="link.children.length">
                <img
                  v-if="!expanded.includes(link._uid)"
                  src="/icons/plus.svg"
                  class="w-20 h-20 desk:!hidden"
                >
                <img
                  v-else
                  src="/icons/minus.svg"
                  class="w-20 h-20 desk:!hidden"
                >
              </div>
            </div>
            <transition name="fade">
              <div v-if="link.children.length && (!isMobile || expanded.includes(link._uid))" class="pt-8">
                <nuxt-link
                  v-for="child in link.children"
                  :key="child._uid"
                  class="block mt-20 first:mt-8 text-darkest underline desk:uppercase desk:type-xs-medium"
                  :to="$sbLinkFix(child.link)"
                  :target="$sbLinkTarget(child.link)"
                >
                  {{ child.title }}
                </nuxt-link>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useGlobalContentStore } from '~/store/globalContent';
import { useUiStore } from '~/store/ui';

import { storeToRefs } from 'pinia';

const uiStore = useUiStore();

const { isMobile } = storeToRefs(uiStore);
const globalContent = useGlobalContentStore();

const navigation = globalContent.getFooterNav;
const footer = globalContent.getFooter;

const router = useRouter();
const expanded = ref<string[]>([]);
const clickMenu = (url:string, children:boolean, _uid:string) => {
  if (children) {
    if (expanded.value.includes(_uid)) {
      expanded.value = expanded.value.filter(v => v !== _uid);
    } else {
      expanded.value.push(_uid);
    }
  } else {
    router.push({ path: `${url}` });
  }
};

// const openCountrySelect = () => {
//   uiStore.openSideMenuWith({ uid: 'selectCountry', ignoreParent: true });
// };

</script>

<style scoped lang="postcss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.logo-banner {
  @apply h-24 bg-brightGreen bg-repeat-x;
  background-image: url(/logos/bywe_logo-footer.svg);
  background-position: 50%;
  background-size: auto 8px;

  @screen desk {
    @apply h-32;
    background-size: auto 16px;
  }
}
</style>
