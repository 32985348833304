<template>
  <div class="SideMenu modalContent modalHeight w-window fixed z-modalsContent">
    <SideMenuContent
      v-if="!ignoreNavigationParent"
      parent=""
      :is-top-parent="true"
    />
    <TransitionGroup name="menu">
      <SideMenuContent
        v-for="(submenu, i) in sideMenuParents"
        :key="submenu"
        class="absolute left-0 top-0"
        :parent="submenu"
        :is-top-parent="(ignoreNavigationParent && i === 0)"
      />
    </TransitionGroup>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import SideMenuContent from '~/components/menu/SideMenuContent.vue';
import { useUiStore } from '~/store/ui';
const uiStore = useUiStore();
const { sideMenuParents, ignoreNavigationParent } = storeToRefs(uiStore);

</script>

<style scoped lang="postcss">
.menu-enter-active {
  transition: all .3s ease-out;
  @apply border-l border-light;
}
.menu-leave-active {
  transition: all .3s ease-in;
  @apply border-l border-light;
}
.menu-enter-from {
  transform: translateX(100%);
}
.menu-leave-to {
  opacity: .0;
  transform: translateX(100%);
}

</style>
