<template>
  <component
    :is="GenericSideMenu"
    position="right"
    class="DeclineOrderProposal"
    :title="$t('mypage.rejectOrderProposal')"
    @close="uiStore.closeAllModal()"
  >
    <p class="text-14 font-medium">{{ $t('mypage.rejectOrderProposal.text') }}</p>
    <Form
      @submit="declineOrderProposal"
      @click="startValidate"
    >
      <div class="mb-16">
        <div v-for="(option, index) in options" :key="'decline-option-' + index">
          <label
            class="flex mb-8"
            :class="{
              'mt-8': index === 0
            }"
            :for="options.value"
          >
            <input
              :id="options.value"
              type="radio"
              :name="option.value"
              :value="option.value"
              :checked="selectedOption.value === option.value"
              @click="selectOption(option)"
            >
            <div class="type-xs-medium desk:type-sm-medium pl-8 relative bottom-[-4px] desk:top-[2px]">
              {{ option.name }}
            </div>
          </label>
        </div>

        <div>
          <Field
            v-slot="{ field, meta }"
            type="textarea"
            name="message"
            :rules="selectedOption.value === 'other' ? validateRequired : null"
          >
            <TextAreaInput
              v-bind="field"
              v-model="message"
              server-name="message"
              :label="$t('mypage.rejectOrderProposal.writeHere')"
              :show-error="meta.validated && !meta.valid"
              :errors="meta.errors"
              :rows="2"
            />
          </Field>
        </div>
      </div>
      <button
        :class="{ 'loading': loading }"
        class="btn w-full mb-24"
      >
        {{ $t('mypage.rejectOrderProposal.send') }}
      </button>

      <FormErrorMessage context="declineOrderProposal" />
    </Form>
  </component>
</template>

<script setup lang="ts">
import GenericSideMenu from '~/components/menu/GenericSideMenu.vue';
import { useUiStore } from '~/store/ui';
import { useUserStore } from '~/store/user';
import { Form, Field } from 'vee-validate';
import useApiFetch from '~/composeables/useApiFetch';
import useValidation from '~/composeables/useValidation';
import TextAreaInput from '~/components/form-elements/TextAreaInput.vue';
import FormErrorMessage from '~/components/form-elements/FormErrorMessage.vue';
import useLinkReplacer from '~/composeables/useLinkReplacer';

const { $t } = useNuxtApp();

const { apiPost, handleApiError } = useApiFetch();

const {
  startValidate,
  validateRequired,
} = useValidation();

const uiStore = useUiStore();
const userStore = useUserStore();
const selectedOption = ref<{ value: string, name: string }>({});
const message = ref('');
const loading = ref(false);

const options = ref([
  { value: 'requirements', name: $t('mypage.rejectOrderProposal.option.requirements') },
  { value: 'price', name: $t('mypage.rejectOrderProposal.option.price') },
  { value: 'brands', name: $t('mypage.rejectOrderProposal.option.brands') },
  { value: 'notSpecify', name: $t('mypage.rejectOrderProposal.option.notSpecify') },
  { value: 'other', name: $t('mypage.rejectOrderProposal.option.other') },
]);

const selectOption = ((option) => {
  selectedOption.value = option;
  message.value =  '';
});

const declineOrderProposal = (async() => {
  loading.value = true;

  const ok = await apiPost('/order-proposals/decline', {
    message: selectedOption.value.value === 'other' ? message.value : selectedOption.value.name + '. ' + message.value,
    uuid: uiStore.currentlyDeclining.basket.id,
  });
  if (!ok) {
    handleApiError('declineOrderProposal', true);
  }

  const { accountUrl } = useLinkReplacer();
  uiStore.setTemporarySuccessMsg($t('mypage.declineOrderProposal.success'));
  uiStore.closeAllModal();
  userStore.removeNotification(uiStore.currentlyDeclining.basket.id);
  navigateTo(accountUrl('orderProposals'));
});

</script>

<style scoped lang="postcss">
</style>
